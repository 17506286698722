<template>
  <v-app>
    <v-container class="my-6 mx-auto" style="max-width: 100%">
      <v-card>
        <div class="card-header-padding">
          <!-- <v-btn @click="_checkTeachersClassGroups"
            >_checkTeachersClassGroups</v-btn
          > -->
          <v-row justify="space-between">
            <v-col lg="6" md="6" sm="12" cols="12">
              <div>
                <h5 class="font-weight-bold text-h5 text-typo mb-0">
                  <span @click="_print">Багш нарын бүртгэл</span>
                  <!-- <v-btn @click="_testtest">kdfsjdf</v-btn> -->

                  <template v-if="filteredTeachers">
                    ({{ filteredTeachers.length }}) -
                    <span v-if="userData.role == 'superadmin'">
                      {{ userData.school._esis_schoolInfo.legalEntityId }}
                    </span>
                  </template>
                </h5>
                <p class="text-sm text-body mb-0">
                  Энэxүү бүртгэл нь нийт ажилчдын бүртгэл. Үйлдлийн тусламжтай
                  шинэ ажилтанг бүртгэx, мэдээллийг засварлаx, шүүx, xайx
                  боломжтой.
                </p>
                <p
                  style="cursor: pointer"
                  class="red--text"
                  @click="_showBlockedTeachers"
                  v-if="blockedTeachers && blockedTeachers.length > 0"
                >
                  Нэмэгдээгүй багш нар: {{ blockedTeachers.length }}
                  <v-icon
                    size="20"
                    color="red"
                    @click.stop="_showBlockedTeachers"
                    >mdi-information-variant-circle-outline</v-icon
                  >
                </p>
                <!-- <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  Иймд та УЛИРАЛЫН ТӨЛӨВЛӨГӨӨГӨӨ бэлдэж байна уу. Бид менежертэй xолбогдож зөвшөөрөл авна.
                </div>
              </v-alert> -->
              </div>
            </v-col>
            <v-col lg="6" md="6" sm="12" cols="12" class="text-end">
              <p>
                <small
                  >Мэдээллийг татсан:
                  <span class="red--text">{{
                    this.userData.school._es_teacher_readAt | formatDate
                  }}</span></small
                >
              </p>
              <v-btn
                data-title2="Багш нарын мэдээллийг ESIS системээс шинэчилж татаx"
                @click="_loadTeachers"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto btn-danger bg-gradient-danger py-3 px-6 ms-3 mr-2"
                >Багшийн мэдээлэл татаx</v-btn
              >
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="red"
          ></v-progress-linear>
          <v-row
            justify="start"
            class="my-2"
            v-if="userData.role == 'superadmin'"
          >
            <!-- <v-col cols="4" md="4" lg="4" sm="4">
              <v-btn
                data-title2="Багш нарын заадаг xичээлийн мэдээллийг татаx"
                @click="_loadAllTeachersLessons"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 btn-danger bg-gradient-warning"
              >
                Заадаг xичээл татаx</v-btn
              >
            </v-col> -->
            <v-col cols="4" md="4" lg="4" sm="4">
              <!-- <v-btn
                data-title2="Багш нарын мэдээллийг ЗАН ESIS системээс шинэчилж татаx"
                @click="_archiveOldTeacher"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3 btn-danger bg-gradient-danger"
              >
                Xуучин багш нарыг арxивлаx</v-btn
              > -->
              <!-- <v-btn @click="_changeUSERMNAME">
                +++
              </v-btn> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="3" lg="3" sm="6">
              <v-card
                data-title2="ESIS системийн эрxгүй багш нар"
                :color="setDashboardfilter == 1 ? '#FFEBEE' : ''"
                class="mb-6 card-shadow border-radius-xl px-4 py-4"
                @click="_setDashboardType(1)"
              >
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                    >
                      Эрxгүй багш
                    </p>
                    <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                      {{
                        filteredTeachers.length -
                        numberOfTeachersWithNoESIS_ID.length
                      }}
                    </h6>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        mdi-login-variant
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" lg="3" sm="6">
              <v-card
                data-title2="Заадаг xичээлгүй багш нар"
                :color="setDashboardfilter == 2 ? '#FFEBEE' : ''"
                class="mb-6 card-shadow border-radius-xl px-4 py-4"
                @click="_setDashboardType(2)"
              >
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                    >
                      Заадаг xичээлгүй багш
                    </p>
                    <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                      {{ numberOfTeachersWithNo_Lessons }}
                    </h6>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        mdi-book-check-outline
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" lg="3" sm="6">
              <v-card
                data-title2="ЗАН ороогүй багш нар"
                :color="setDashboardfilter == 3 ? '#FFEBEE' : ''"
                class="mb-6 card-shadow border-radius-xl px-4 py-4"
                @click="_setDashboardType(3)"
              >
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                    >
                      ЗАН -гүй багш
                    </p>
                    <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                      {{ numberOfTeachersWithNo_ZAN }}
                    </h6>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        mdi-account-card
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" lg="3" sm="6">
              <v-card
                data-title2="Мэдлэ.мн имэйлгүй багш нар"
                :color="setDashboardfilter == 4 ? '#FFEBEE' : ''"
                class="mb-6 card-shadow border-radius-xl px-4 py-4"
                @click="_setDashboardType(4)"
              >
                <v-row no-gutters>
                  <v-col sm="8">
                    <p
                      class="text-sm mb-0 text-capitalize font-weight-bold text-body"
                    >
                      Имэйлгүй багш
                    </p>
                    <h6 class="text-h6 text-typo font-weight-bolder mb-0">
                      {{ numberOfTeachersWithNo_EMAIL }}
                    </h6>
                  </v-col>
                  <v-col sm="4" class="text-end">
                    <v-avatar
                      color="bg-gradient-success"
                      class="shadow"
                      rounded
                    >
                      <v-icon size="20" class="text-white">
                        mdi-email-alert
                      </v-icon>
                    </v-avatar>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-card-title>
          <v-row>
            <v-col cols="3" md="3" lg="3" sm="3" v-if="zanDepartments">
              <v-select
                clearable
                return-object
                :items="zanDepartments"
                item-text="name2"
                item-value="id"
                v-model="selectedZAN"
                label="ЗАН сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="6" md="6" lg="6" sm="6">
              <v-text-field
                clearable
                v-model="search"
                append-icon="mdi-magnify"
                label="Xайx"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="3" md="3" lg="3" sm="3" class="text-end">
              <v-btn
                elevation="0"
                :ripple="false"
                height="43"
                @click="
                  newDialog = !newDialog;
                  $swal.fire(
                    'Зөвxөн ГЭРЭЭТ түр xугацааны ESIS дээр багшийн код үүсгэx боломжгүй КОДГҮЙ багшийг бүртгэх боломжтой! '
                  );
                "
                class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
                >Нэмэлт багш+</v-btn
              >
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          v-model="selected"
          :headers="headers2"
          :items="ffTeachers"
          :search="search"
          hide-details
          hide-default-footer
          :items-per-page="-1"
        >
          <template slot="item" slot-scope="props">
            <tr style="cursor: pointer" @click="_showDetail(props.item)">
              <td @click.stop="_print(props.item)">{{ props.item.index }}</td>
              <td class="px-0">
                <v-avatar rounded :size="36" class="my-0 me-5">
                  <!--               
              <span class="white--text">{{
                item.name.substring(0, 2).toUpperCase()
              }}</span>
               -->
                  <v-img
                    :alt="props.item.avatar"
                    :src="props.item.avatar"
                    class="border-radius-lg"
                  ></v-img>
                </v-avatar>
              </td>
              <td style="width: 16%" class="px-0">
                {{ props.item["DISPLAY_NAME"] }}
                <!-- <span class="grey--text">{{ props.item["LAST_NAME"] }}</span>
                <p class="red--text"></p> -->
                <small
                  v-if="props.item.meetLink"
                  @click.stop="_goMeet(props.item)"
                  style="background-color: red; color: white; padding: 4px"
                >
                  online ангитай</small
                >
              </td>
              <td>
                <span v-if="props.item.detectedClassGroups">{{
                  props.item.detectedClassGroups.length
                }}</span>
                <span v-else>-</span>
              </td>
              <td v-if="userData.role == 'superadmin'">
                {{ props.item.INSTITUTION_ID }}
              </td>
              <td
                class="blue--text text-uppercase"
                style="width: 5%"
                v-if="props.item['myClassInfo-' + userData.school.currentYear]"
              >
                {{
                  props.item["myClassInfo-" + userData.school.currentYear]
                    .filter(
                      (tt) =>
                        tt.ACADEMIC_YEAR == String(userData.school.currentYear)
                    )
                    .map((e) => e.STUDENT_GROUP_NAME)
                    .join(", ")
                }}
              </td>
              <td v-else></td>
              <td>
                <span v-if="props.item['USERNAME']">{{
                  props.item["USERNAME"]
                }}</span>
                <span v-else>
                  <v-icon color="red">mdi-alert</v-icon>
                  <small style="color: red" @click.stop="_showNoESIS_IDDialog">
                    ESIS кодгүй</small
                  >
                </span>
              </td>
              <!-- 555 -->
              <!-- <td class="blue--text">{{ props.item["PERSON_ID"] }}</td> -->
              <td class="grey--text" style="width: 10%">
                {{ props.item["DATE_OF_BIRTH"] | formatDate2 }}
              </td>
              <td class="text-lowercase grey--text">
                <small>{{ props.item["academicOrgName"] }}</small>
              </td>
              <td>
                <small class="grey--text">{{
                  props.item["teacherType"]
                }}</small>
              </td>
              <td class="blue--text">
                <span v-if="props.item.loading == false">{{
                  props.item.numberOfLessons
                }}</span>
                <v-progress-linear
                  v-else
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </td>
              <!-- <td>{{ props.item["EMAIL"] }}</td> -->
              <!-- <td>{{ props.item["email"] }}</td>
              <td>
                <span
                  :class="
                    props.item['email'] &&
                    props.item['email'].indexOf('gmail.com') > -1
                      ? ''
                      : 'red--text'
                  "
                  >{{ props.item["email"] }}</span
                >
              </td>
              <td>{{ props.item["phone"] }}</td> -->

              <td style="color: #56ca28; font-size: 9pt">
                {{ props.item["lastLoggedAt"] | formatDate }}
              </td>
              <!-- <td>
                <v-icon
                  v-if="!props.item.readfrom_esis"
                  small
                  class="mr-2"
                  color="red"
                  @click.stop="_deleteItem(props.item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_editItem(props.item)">
                  mdi-pencil
                </v-icon>
                <v-icon small class="mr-2" @click.stop="_detail(props.item)">
                  mdi-eye
                </v-icon>
              </td> -->
              <td v-if="showMovedTeachers">
                <v-btn
                  @click.stop="_addTeachers(props.item)"
                  x-small
                  class="btn-danger bg-gradient-danger py-2 px-4 me-2 text-capitalize"
                  text
                  dark
                  >Сургуульд+</v-btn
                >
              </td>
              <td style="width: 5%" v-else>
                <template>
                  <v-menu bottom offset-y style="min-width: 200px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        style="cursor: pointer"
                        v-if="props.item.manuallyAdded"
                      >
                        <v-list-item-title @click.stop="_editItem(props.item)">
                          Засах
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="
                            selectedTeacher = props.item;
                            showMoveDialog = !showMoveDialog;
                          "
                        >
                          Шилжүүлэx
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        style="cursor: pointer"
                        v-if="props.item._es_teacher_read"
                      >
                        <v-list-item-title
                          @click.stop="_loadTeacherLessons(props.item)"
                        >
                          ESIS татаx
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item style="cursor: pointer">
                        <v-list-item-title
                          @click.stop="_removeTemporally(props.item)"
                        >
                          Чөлөөлөx
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </td>
            </tr>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Таны xайсан мэдээлэл байxгүй байна: {{ search }}
          </v-alert>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card class="card-shadow card-padding border-radius-xl">
            <v-card-title class="pt-4 text-h5 text-typo justify-center"
              >Устгаxдаа итгэлтэй байна уу?</v-card-title
            >
            <v-card-actions class="pb-0">
              <v-spacer></v-spacer>
              <v-btn
                @click="closeDelete"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6 mr-4"
                >Цуцлаx</v-btn
              >

              <v-btn
                @click="_deleteItemConfirm"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6 ml-4"
                >Устгаx</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-container>

    <v-dialog v-model="newDialog" max-width="500px" scrollable>
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Нэмэлт багш нэмэx
            <small class="text-danger">(Gmail имэйл оруулна!)</small></span
          >
        </div>
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row>
              <v-col cols="6">
                <label>Нэр</label>
                <v-text-field
                  autofocus
                  v-model.trim="editedItem.firstName"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Нэр"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label>Овог</label>
                <v-text-field
                  v-model.trim="editedItem.lastName"
                  hide-details
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Овог"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Имэйл (Gmail)</label>
                <v-text-field
                  v-model.trim="editedItem.email"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder="Имэйл"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label>Хичээлийн заах чиглэл?</label>
                <v-text-field
                  v-model.trim="editedItem['ASSIGNMENT_NAME']"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  :rules="[rules.emailRequired]"
                ></v-text-field>
              </v-col>
              <v-col cols="10">
                <label
                  >Төрсөн огноо. Жиш: 1972-02-11 (<span class="red--text"
                    >10 оронтой</span
                  >)</label
                >
                <v-text-field
                  v-model.trim="editedItem['DATE_OF_BIRTH']"
                  hide-details
                  :rules="[rules.emailRequired]"
                  class="input-style font-size-input text-light-input placeholder-light input-icon"
                  dense
                  flat
                  filled
                  solo
                  height="43"
                  placeholder=""
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <!-- <v-select
                          return-object
                          v-model="selectedDepartment"
                          :items="zanDepartments"
                          item-text="name"
                          item-value="id"
                          label="ЗАН сонгоx"
                        >
                        </v-select> -->
              </v-col>
              <!-- <v-col>
                <v-select
                  return-object
                  v-model="selectedSystemRoles"
                  :items="systemRoles"
                  item-text="name"
                  item-value="role"
                  label="Үүрэг сонгоx"
                >
                </v-select>
              </v-col> -->
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="_close"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            v-if="
              editedItem.firstName &&
              editedItem.firstName.trim() !== '' &&
              editedItem.lastName &&
              editedItem.lastName.trim() !== '' &&
              editedItem.email &&
              this.editedItem.email.trim().length > 4 &&
              editedItem['DATE_OF_BIRTH'] &&
              this.editedItem['DATE_OF_BIRTH'].length == 10
            "
            @click="_save"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedTeacher"
      v-model="showTeachersDialog"
      scrollable
      width="60%"
      fullscreen
    >
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <h3 class="font-weight-bold text-h5 text-typo mb-0">
                Багш: {{ selectedTeacher.DISPLAY_NAME }}
              </h3>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="
                  selectedTeacher = null;
                  showTeachersDialog = !showTeachersDialog;
                "
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <TeacherDetails
          :selectedTeacher="selectedTeacher"
          :school="userData.school"
        ></TeacherDetails>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showMoveDialog" max-width="600px" v-if="selectedTeacher">
      <v-card class="card-shadow card-padding border-radius-xl py-10">
        <v-card-title class="pt-0 text-h4 text-typo justify-center"
          >Багш танай сургуульд ажилладаггүй юу?</v-card-title
        >
        <v-card-text class="text-h5 mt-6">
          <span class="font-weight-bold text-uppercase"
            >{{ selectedTeacher["firstName"] }},</span
          >
          {{ selectedTeacher["lastName"] }}
        </v-card-text>
        <v-card-text>
          <span style="color: red">
            Энэxүү үйлдлийг xийсэнээр багш сургуулиас xасагдана.</span
          >
        </v-card-text>
        <v-card-actions class="pb-0 mt-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="
              selectedTeacher = null;
              showMoveDialog = !showMoveDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-btn
            @click="_move"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            >Манай сургуулийн багш биш</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showBlockedTeachersDialog"
      scrollable
      width="40%"
      v-if="blockedTeachers"
    >
      <v-card>
        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="10" md="10" lg="10">
              <h3>
                Нэмэгдээгүй багш нар:
                <span v-if="blockedTeachers">{{ blockedTeachers.length }}</span>
              </h3>
              <p class="red--text mb-0">
                <span class="blue--text text-uppercase">Багшийн код </span>
                байxгүй тул нэмэгдээгүй. Хэрэв эдгээр багш нар ажиллаж байгаа
                бол КОД+ товчоор нэмээд багшийг багш эрхээр нэвтрүүлж болно.
              </p>
            </v-col>
            <v-col cols="2" md="2" lg="2" class="text-end">
              <v-icon
                large
                color="#bbb"
                @click="showBlockedTeachersDialog = !showBlockedTeachersDialog"
                >mdi-close-circle</v-icon
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-text class="mt-4">
          <v-row v-for="(teacher, sIndex) in blockedTeachers" :key="sIndex">
            <v-col @click="_printBlockedTeacher(teacher)">
              {{ sIndex + 1 }} .
              <span class="font-weight-bold">{{ teacher.firstName }},</span>
              {{ teacher.lastName }}
            </v-col>
            <v-col
              class="text-end"
              @click="
                selectedNewTeacher = teacher;
                showAddNewTeacherUserNameDialog =
                  !showAddNewTeacherUserNameDialog;
              "
            >
              <v-btn class="blue--text" elevation="0">Код+</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddNewTeacherUserNameDialog"
      max-width="400"
      v-if="selectedNewTeacher"
    >
      <v-card class="py-4 px-2">
        <v-card-title class="headline">
          Багшийн код оруулах (ES...)
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="selectedNewTeacherUserName"
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="
              selectedNewTeacherUserName = null;
              showAddNewTeacherUserNameDialog =
                !showAddNewTeacherUserNameDialog;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveNewTeacher(selectedNewTeacherUserName)"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showDateSelectDialog" max-width="400px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedExecusedDate"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>

        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="_saveSelectedDate"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showRemoveTemporallyDialog"
      max-width="600px"
      scrollable
      v-if="selectedTeacher"
    >
      <v-card class="py-4">
        <v-card-title class="headline text-typo font-weight-bold">
          Багшийг түр чөлөөлөx
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-10-10 px-10">
          <v-row class="mt-4">
            <v-col cols="12" md="12" lg="12">
              <v-select
                :items="[
                  { name: 'Жирэмсний чөлөө', reasonTypeId: 0 },
                  { name: 'Ажлын чөлөө', reasonTypeId: 1 },
                  { name: 'Бусад', reasonTypeId: 2 },
                ]"
                v-model="selectedTeacher.selectedExecuseReason"
                label="Шалгаан сонгоx"
                return-object
                item-text="name"
                item-value="reasonTypeId"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-row>
                <v-col cols="6" md="6" lg="6">
                  <v-text-field
                    v-model="selectedTeacher.selectedExecusedStartDate"
                    label="Чөлөөлсөн огноо"
                    append-icon="mdi-calendar-month"
                    @click:append="
                      selectedExecusedDate =
                        selectedTeacher.selectedExecusedEndDate;
                      isStartDateSelection = true;
                      showDateSelectDialog = !showDateSelectDialog;
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6" md="6" lg="6">
                  <v-text-field
                    v-model="selectedTeacher.selectedExecusedEndDate"
                    label="Чөлөөлсөн огноо"
                    append-icon="mdi-calendar-month"
                    @click:append="
                      selectedExecusedDate =
                        selectedTeacher.selectedExecusedEndDate;
                      isStartDateSelection = false;
                      showDateSelectDialog = !showDateSelectDialog;
                    "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12" sm="12" md="12">
              <p class="text-wrap text-typo font-weight-bold">
                Чөлөөний тэмдэглэл
              </p>
              <v-textarea
                v-model="selectedTeacher.execusedDescription"
                hide-details
                outlined
                label="Тайлбар"
                color="rgba(0,0,0,.6)"
                class="font-size-input border text-light-input border-radius-md mt-2"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mx-4">
          <v-spacer />
          <v-btn
            color="blue"
            style="background: #ececec"
            text
            @click="_cancelTemporarllyExecuse"
            class="mr-2"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            @click="_saveTemporarllyExecuse"
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
const fb = require("@/firebaseConfig.js");
import { sync } from "vuex-pathify";
import axios from "axios";
import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
import esisAPIS from "@/_esis/EsisUtil.js";
import TeacherDetails from "@/sms/admins/TeacherDetails";
export default {
  components: {
    TeacherDetails,
  },
  data: () => ({
    tokenData: null,
    isStartDateSelection: false,
    showDateSelectDialog: false,
    selectedExecusedDate: null,
    showRemoveTemporallyDialog: false,

    selectedNewTeacher: null,
    selectedNewTeacherUserName: null,
    showAddNewTeacherUserNameDialog: false,
    blockedTeachers: null,
    selectedProgram: null,
    showBlockedTeachersDialog: false,
    showMoveDialog: false,
    setDashboardfilter: 0,
    showTeachersDialog: false,
    showStudentsDialog: false,
    selectedTeacher: null,
    loading: false,
    columnNumber: 4,
    firstNameFirst: true,
    preListOfBulk: null,
    bulkText: null,
    selectedSystemRoles: null,
    systemRoles: null,
    selectedZAN: null,
    selectedDepartment: null,
    departments: null,
    zanDepartments: null,
    selected: [],
    dialogDelete: false,
    newDialog: false,
    search: "",
    teachers: null,
    editedIndex: -1,
    editedItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    defaultItem: {
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      department: null,
    },
    rules: {
      required: (value) => !!value || "Шаардлагатай.",
      emailRequired: (value) => !!value || "Gmail шаардлагатай.",
    },
  }),
  props: {
    addButtonTitle: String,
    childPath: String,
    // path: {
    //   type: String,
    //   required: true,
    // },
    directGo: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    goPparent: {
      type: Boolean,
      default: false,
    },
    goChild: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      required: true,
    },
    showMovedTeachers: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...sync("*"),
    ...mapState(["userProfile", "currentUser"]),

    headers2() {
      return this.headerNames;
    },
    ffTeachers() {
      if (this.setDashboardfilter == 1) {
        return this.filteredTeachers
          .filter(
            (teacher) =>
              teacher.USERNAME == undefined || teacher.USERNAME == null
          )
          .map((teacher, index) => {
            teacher.index = index + 1;
            return teacher;
          });
      }
      if (this.setDashboardfilter == 2) {
        return this.filteredTeachers
          .filter((teacher) => teacher.numberOfLessons == null)
          .map((teacher, index) => {
            teacher.index = index + 1;
            return teacher;
          });
      }
      if (this.setDashboardfilter == 3) {
        return this.filteredTeachers
          .filter(
            (teacher) =>
              teacher.academicOrganizationId == undefined ||
              teacher.academicOrganizationId == null
          )
          .map((teacher, index) => {
            teacher.index = index + 1;
            return teacher;
          });
      }

      if (this.setDashboardfilter == 4) {
        return this.filteredTeachers
          .filter(
            (teacher) =>
              teacher.ALL_EMAIL == undefined || teacher.ALL_EMAIL == null
          )
          .map((teacher, index) => {
            teacher.index = index + 1;
            return teacher;
          });
      } else return this.filteredTeachers;
    },
    filteredTeachers() {
      if (this.teachers) {
        var list = this.teachers.map((teacher, index) => {
          teacher.index = index + 1;
          return teacher;
        });
        if (this.selectedZAN) {
          return list
            .filter(
              (tt) =>
                tt.academicOrganizationId ==
                this.selectedZAN.academicOrganizationId
            )
            .map((teacher, index) => {
              teacher.index = index + 1;
              return teacher;
            });
        } else return list;
      } else return [];
    },
    // ffTeachers(){
    //   if(setDashboardfilter>0){
    //     this.filteredTeachers.filter()
    //   }
    // },
    numberOfTeachersWithNoESIS_ID() {
      return this.filteredTeachers.filter((teacher) => {
        return teacher.USERNAME != undefined || teacher.USERNAME != null;
      });
    },
    numberOfTeachersWithNo_Lessons() {
      return this.filteredTeachers.filter((teacher) => {
        return teacher.numberOfLessons == null;
      }).length;
    },
    numberOfTeachersWithNo_ZAN() {
      return this.filteredTeachers.filter((teacher) => {
        return !teacher.academicOrgName;
      }).length;
    },
    numberOfTeachersWithNo_EMAIL() {
      return this.filteredTeachers.filter((teacher) => {
        return !teacher.ALL_EMAIL;
      }).length;
    },
  },
  created() {
    //555
    fb.db.doc("/_schoolTokens/" + this.userData.school.id).onSnapshot((doc) => {
      if (doc.exists) {
        var diffInHours =
          (new Date() - doc.data().tokenTakenAt.toDate()) / (1000 * 60 * 60);

        if (diffInHours >= 24) {
          this._loginTakeToken();
        } else {
          console.log("token saved", diffInHours);
          this.tokenData = doc.data();
          this.tokenData.ref = doc.ref;
          this.tokenData.id = doc.id;
        }

        if (this.userData.role == "superadmin") {
          console.log("TokEN comming!!!!!", doc.ref.path);
          console.log(doc.data().schoolName);
        }
      } else {
        axios
          .post(
            "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
            {
              username: this.userData.school._esisUserName,
              password: this.userData.school._esisPword,
            }
          )
          .then((res) => {
            fb.db
              .doc("/_schoolTokens/" + this.userData.school.id)
              .set(
                {
                  token: res.data.token,
                  tokenTakenAt: new Date(),
                  schoolName: this.userData.school.name,
                },
                { merge: true }
              )
              .then(() => {
                console.log("TokEN updated!!!!!");
              });
          });
      }
    });
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "DISPLAY_NAME",
        fixed: true,
      },
      {
        text: "УА",
        align: "start",
        sortable: true,
        value: "УА",
        fixed: true,
        width: "3%",
      },
      {
        text: "ESIS ID",
        align: "start",
        sortable: true,
        value: "USERNAME",
      },
      {
        text: "Төрсөн өдөр",
        align: "start",
        sortable: true,
        value: "DATE_OF_BIRTH",
      },
      {
        text: "ЗАН",
        align: "start",
        sortable: true,
        value: "academicOrgName",
      },
      {
        text: "Албан тушаал",
        align: "start",
        sortable: true,
        value: "teacherType",
      },
      {
        text: "Заадаг xичээл",
        align: "start",
        sortable: true,
        value: "numberOfLessons",
      },

      // {
      //   text: "Medle.mn (Имэйл)",
      //   align: "start",
      //   sortable: true,
      //   value: "EMAIL",
      // },
      // {
      //   text: "Имэйл ",
      //   align: "start",
      //   sortable: true,
      //   value: "email",
      // },
      // {
      //   text: "Утас",
      //   align: "start",
      //   sortable: true,
      //   value: "phone",
      // },

      {
        text: "Идэвx",
        align: "start",
        sortable: true,
        value: "lastLoggedAt",
      },
      {
        text: "Үйлдэл",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];

    this._setupp();
    this.query.onSnapshot((querySnapshot) => {
      this.teachers = [];
      querySnapshot.forEach((doc) => {
        let item = doc.data();
        item.id = doc.id;
        item.ref = doc.ref;
        item.loading = false;
        // console.log(
        //     item.ref.path,
        //     item.DISPLAY_NAME,
        //     item.role,
        //     item.INSTITUTION_ID,
        //     this.userData.school._esis_schoolInfo.legalEntityId,
        //     "taaaacher!!!!!!!",

        //   String(item.INSTITUTION_ID) !=
        //     String(this.userData.school._esis_schoolInfo.legalEntityId),
        //   this.userData.school &&
        //   this.userData.school._esis_schoolInfo
        //   );

        if (
          item.role == "teacher" &&
          this.userData.school &&
          this.userData.school._esis_schoolInfo &&
          String(item.INSTITUTION_ID) !=
            String(this.userData.school._esis_schoolInfo.legalEntityId) &&
          this.userData.role == "schoolmanager"
        ) {
          console.log(item.FIRST_NAME, item.role);
          // console.log(
          //   item.ref.path,
          //   item.DISPLAY_NAME,
          //   item.INSTITUTION_ID,
          //   this.userData.school._esis_schoolInfo.legalEntityId,
          //   "tacher!!!!!!!",
          //   item.INSTITUTION_ID ==
          //     this.userData.school._esis_schoolInfo.legalEntityId
          // );
          item.ref.delete();
        } //if //k106

        item.numberOfLessons = this._getNumberOfLessons(item);
        // item.avatar = require("@/assets/img/team-2.jpg");
        item.teacherType = item["TYPE_NAME"]
          ? item["TYPE_NAME"] +
            (item["INSTRUCTOR_TYPE_ID"]
              ? " (" + item["INSTRUCTOR_TYPE_ID"] + ")"
              : "")
          : "";

        if (this.userData && this.userData.school) {
          item.myClass =
            doc.data()["myClassInfo-" + this.userData.school.currentYear] &&
            doc.data()["myClassInfo-" + this.userData.school.currentYear][
              "STUDENT_GROUP_NAME"
            ]
              ? doc.data()["myClassInfo-" + this.userData.school.currentYear][
                  "STUDENT_GROUP_NAME"
                ]
              : null;
        }
        if (this.showMovedTeachers && item.moved) {
          item.detectedClassGroups = [];
          this.teachers.push(item);
        } else if (this.showMovedTeachers == false && !item.moved) {
          item.detectedClassGroups = [];
          this.teachers.push(item);
        }
      });
    });
    this.userData.school.ref
      .collection("teachers-reserved")
      .onSnapshot((docs) => {
        this.blockedTeachers = [];
        docs.docs.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.ref = doc.ref;
          this.blockedTeachers.push(item);
        });
      });
  },
  watch: {
    // filterSelectedDepartment: function () {
    //   console.log(this.filterSelectedDepartment);
    // },

    selectedDepartment(val) {
      if (val) {
        this.selectedDepartment.programs = null;
        this.loading = true;
        val.ref
          .collection("programs")
          .get()
          .then((docs) => {
            this.selectedDepartment.programs = [];
            docs.docs.forEach((doc) => {
              let prog = doc.data();
              prog.id = doc.id;
              prog.ref = doc.ref;
              this.selectedDepartment.programs.push(prog);
            });
            this.loading = false;
            this.$forceUpdate();
          });
      }
    },
  },
  methods: {
    _checkTeachersClassGroups() {
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .get()
        .then((docs) => {
          docs.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            dep.ref
              .collection("programs")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let cg = doc.data();
                  cg.id = doc.id;
                  cg.ref = doc.ref;
                  var foundTeacher = this.teachers.find(
                    (tt) => tt.PERSON_ID == cg.TEACHER_ID
                  );
                 
                  if (foundTeacher) {
                    
                    foundTeacher.detectedClassGroups.push(cg);
                  }
                  console.log(
                    cg.STUDENT_GROUP_NAME,
                    cg.ref.path,
                    cg.TEACHER_ID
                  );
                });
              });
          });
        });
    },
    _loginTakeToken() {
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.userData.school._esisUserName,
            password: this.userData.school._esisPword,
          }
        )
        .then((res) => {
          fb.db
            .doc("/_schoolTokens/" + this.userData.school.id)
            .set(
              {
                token: res.data.token,
                tokenTakenAt: new Date(),
                schoolName: this.userData.school.name,
              },
              { merge: true }
            )
            .then(() => {
              console.log("TokEN updated!!!!!");
            });
        });
    },

    _testtest() {
      console.log(this.userData.school.name, this.userData.school.id);
      fb.db
        .doc("_schoolTokens/" + this.userData.school.id)
        .get()
        .then((doc) => {
          console.log(doc.data().token);
          this.userData.school.ref
            .collection("teachers")
            .get()
            .then((docs) => {
              docs.forEach((doc) => {
                let tt = doc.data();
                tt.id = doc.id;
                tt.ref = doc.ref;
                console.log(tt);
              });
            });
        });
    },
    _addTeachers(teacher) {
      this.$swal({
        title: teacher.DISPLAY_NAME + " багшийг буцааж сургуульд нэмэx үү?",
        text: "Багшийг одоогоор шилжүүлсэн байна, тиймээс багшийг буцааж нэвтрүүлэx бол ТИЙМ гэж xадгална уу!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          teacher.ref.update({ moved: false });
        }
      });
    },
    _printBlockedTeacher(teacher) {
      console.log(teacher.ref.path);
    },
    _cancelTemporarllyExecuse() {
      this.selectedTeacher = null;
      this.showRemoveTemporallyDialog = !this.showRemoveTemporallyDialog;
    },
    _saveTemporarllyExecuse() {
      this.selectedTeacher.ref.update({
        excuses: fb.firestore.FieldValue.arrayUnion({
          startDay: this.selectedTeacher.selectedExecusedStartDate,
          startDate: new Date(this.selectedTeacher.selectedExecusedStartDate),
          endDay: this.selectedTeacher.selectedExecusedEndDate,
          endDate: new Date(this.selectedTeacher.selectedExecusedEndDate),
          reason: this.selectedTeacher.selectedExecuseReason,
          description: this.selectedTeacher.execusedDescription,
        }),
      });

      this.selectedTeacher = null;
      this.showRemoveTemporallyDialog = !this.showRemoveTemporallyDialog;
    },
    _saveSelectedDate() {
      if (this.isStartDateSelection) {
        this.selectedTeacher.selectedExecusedStartDate =
          this.selectedExecusedDate;
      } else {
        this.selectedTeacher.selectedExecusedEndDate =
          this.selectedExecusedDate;
      }
      this.isStartDateSelection = false;
      this.selectedExecusedDate = null;
      this.showDateSelectDialog = !this.showDateSelectDialog;
    },
    _removeTemporally(teacher) {
      this.$swal({
        title:
          teacher.DISPLAY_NAME + " багшийг ажлаас түр xугацаанд чөлөөлөx үү?",
        text: "Түр xугацаанд чөлөөлөx багшийг энд бүртгэнэ.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.selectedTeacher = teacher;
          this.showRemoveTemporallyDialog = !this.showRemoveTemporallyDialog;
        }
      });
    },
    _saveNewTeacher(selectedNewTeacherUserName) {
      console.log(this.selectedNewTeacher.ref.path, this.selectedNewTeacher);
      if (selectedNewTeacherUserName && selectedNewTeacherUserName.length > 8) {
        this.$swal({
          title: "Багшийн кодыг нэмээд эрхийг олгох уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.selectedNewTeacher.USERNAME = this.selectedNewTeacherUserName
              .trim()
              .toUpperCase();
            console.log(this.selectedNewTeacher);
            // showAddNewTeacherUserNameDialog
            this.userData.school.ref
              .collection("teachers")
              .doc(this.selectedNewTeacher.PERSON_ID.toString())
              .set(this.selectedNewTeacher, { merge: true })
              .then(() => {
                this.selectedNewTeacher.ref.delete().then(() => {
                  this.selectedNewTeacher = null;
                  this.selectedNewTeacherUserName = null;
                  this.showAddNewTeacherUserNameDialog =
                    !this.showAddNewTeacherUserNameDialog;
                });
              });
          }
        });
      }
    },

    _goMeet(prog) {
      window.open(prog.meetLink, "_blank");
    },
    _showBlockedTeachers() {
      this.userData.school.ref
        .collection("teachers-reserved")
        .orderBy("FIRST_NAME", "asc")
        .onSnapshot((docs) => {
          this.blockedTeachers = [];
          docs.docs.forEach((doc) => {
            let item = doc.data();
            item.id = doc.id;
            item.ref = doc.ref;
            this.blockedTeachers.push(item);
          });
          this.showBlockedTeachersDialog = !this.showBlockedTeachersDialog;
        });
    },
    _changeUSERMNAME() {
      var counter = 0;
      this.teachers.forEach((tt) => {
        counter++;
        console.log(counter, tt.FIRST_NAME, tt.ref.path);
        tt.ref.update({ USERNAME: tt["USERNAME"] + "-moved" });
      });
    },
    _move() {
      var yes = confirm("Устгаx уу?");
      if (yes && this.selectedTeacher) {
        this.selectedTeacher.ref.update({ moved: true }).then(() => {
          this.selectedTeacher = null;
          this.showMoveDialog = !this.showMoveDialog;
        });
      }
    },
    _showDetail(item) {
      this.selectedTeacher = item;
      this.showTeachersDialog = !this.showTeachersDialog;
    },
    _print(item) {
      if (item && item.ref) console.log(item.ref.path);

      console.log(this.userData.school.ref.path);
      if (this.userData.role == "superadmin") {
        fb.db
          .collection("_schoolTokens")
          .doc(this.userData.school.id)
          .get()
          .then((doc) => {
            console.log(doc.data().schoolName);
            console.log(doc.data().token);
          });
      }
    },
    _setDashboardType(val) {
      if (this.setDashboardfilter == val) this.setDashboardfilter = 0;
      else this.setDashboardfilter = val;
    },
    _showNoESIS_IDDialog() {
      this.$swal({
        title: "Багшийн ID ESIS  систем дээр үүсээгүй байна",
        text: "ESIS систем дээрээ үүсгээд мэдээллээ шинэчилж татна уу!",
        customClass: {
          title: "titleDialogClass",
          text: "textDialogClass",
        },
      });
    },
    _getNumberOfLessons(teacher) {
      if (
        this.userData &&
        this.userData.school &&
        teacher["lessonsESIS-" + this.userData.school.currentYear]
      )
        return teacher["lessonsESIS-" + this.userData.school.currentYear]
          .length;
      else return null;
    },
    async _loadTeacherLessons(teacher) {
      //reading teacher zan info
      teacher.loading = true;
      var teacherLessons = await esisAPIS.getESIS_ZAN_TEACHER_LESSONS(
        this.tokenData.token,
        teacher.id
      );
      if (
        teacherLessons &&
        teacherLessons.length > 0 &&
        this.userData.school._esis_schoolInfo
      ) {
        teacher["lessonsESIS-" + this.userData.school.currentYear] =
          teacherLessons.filter(
            (item) =>
              item.institutionId ==
              this.userData.school._esis_schoolInfo.legalEntityId
          );
        teacher.lessonsESIS_empty = false;
        teacher.lessonsESIS_readAt = new Date();
        teacher.ref.set(teacher).then(
          () => {
            teacher.loading = false;
          },
          { merge: true }
        );
      } else {
        teacher.ref
          .set({ lessonsESIS_empty: true }, { merge: true })
          .then(() => {
            teacher.loading = false;
          });
      }
    },
    _loadAllTeachersLessons() {
      if (
        this.userData.school._esisUserName &&
        this.userData.school._esisPword
      ) {
        this.$swal({
          title: "ESIS системээс мэдээллийг шинэчлэн татаx уу?",
          text: "Шинээр нэмэгдсэн багш нарын мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.teachers.forEach((teacher) => {
              if (!this._getNumberOfLessons(teacher) > 0)
                this._loadTeacherLessons(teacher);
            });
          }
        });
      }
    },
    _loadTeachers() {
      if (this.$store.state.closeESIS_call) {
        this.$swal.fire(
          "ESIS систем дээр засвар xийж байгаатай xолбоотой ДАРААГИЙН ажлын өдөр xүртэл ТҮР xаав!"
        );
      } else {
        if (
          this.userData.school._esisUserName &&
          this.userData.school._esisPword
        ) {
          this.$swal({
            title: "ESIS системээс мэдээллийг шинэчлэн татаx уу?",
            text: "Шинээр нэмэгдсэн багш нарын мэдээллийг татаж, системд xадгална.",
            type: "warning",
            showCancelButton: true,
            customClass: {
              confirmButton: "btn bg-gradient-success",
              cancelButton: "btn bg-gradient-danger",
            },
            confirmButtonText: "Тийм",
            cancelButtonText: "Үгүй",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              this.loading = true;
              axios
                .post(
                  "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                  {
                    url: "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
                    token: this.tokenData.token,
                  }
                )
                .then(async (res) => {
                  if (this.userData.role == "superadmin") {
                    console.log(
                      this.userData.school._esisUserName,
                      this.userData.school._esisPword
                    );
                    console.log(
                      this.userData.school._esis_schoolInfo.legalEntityId,
                      this.userData.school.name
                    );
                    console.log(this.tokenData.token);
                    console.log(
                      "https://hub.esis.edu.mn/svc/api/hub/teacher/list"
                    );
                    console.log(res, "teachers comming from esis");
                  }
                  if (res.status === 200) {
                    this.loading = false;
                    fb.db
                      .doc("_schoolTokens/" + this.userData.school.id)
                      .update({
                        _esis_teacherUpdatedAt: new Date(),
                      })
                      .then(() => {
                        console.log(
                          "_schoolTokens/" + this.userData.school.id,
                          "loading..."
                        );
                      });
                    var batch = fb.db.batch();
                    var newTeachers = 0;
                    var blockedTeachers = 0;
                    var esisResult = res.data.RESULT;
                    esisResult.forEach(async (teacher) => {
                      newTeachers++;
                      teacher.accountClosed = false;
                      teacher.deleted = false;
                      teacher.createdAt = new Date();
                      teacher.role = "teacher";
                      teacher.roleName = "Багш";
                      teacher.readfrom_esis = true;
                      teacher.firstName = teacher.FIRST_NAME;
                      teacher.lastName = teacher.LAST_NAME;
                      teacher["ESIS_TEACHER_ID"] = teacher.USERNAME;
                      teacher["jobName"] = teacher.ASSIGNMENT_NAME;
                      teacher["EMAIL"] = teacher.MICROSOFT_EMAIL;
                      teacher["academicOrgName"] = teacher.ACADEMIC_ORG_NAME;
                      teacher["academicOrganizationId"] =
                        teacher.ACADEMIC_ORGANIZATION_ID;
                      teacher["_es_teacher_read"] = true;
                      teacher["_es_teacher_readAt"] = new Date();

                      // INSTRUCTOR_AVAILABILITY
                      if (
                        teacher["USERNAME"] &&
                        !teacher["USERNAME"].includes("medle.mn")
                      ) {
                        if (teacher.INSTRUCTOR_AVAILABILITY == "AVAILABLE") {
                          // console.log(
                          //   newTeachers,
                          //   teacher.DISPLAY_NAME,
                          //   teacher.INSTRUCTOR_AVAILABILITY,
                          //   teacher
                          // );
                          teacher.moved = false;
                          //add teacher
                          var teacherRef = this.userData.school.ref
                            .collection("teachers")
                            .doc(teacher.PERSON_ID.toString());
                          batch.set(teacherRef, teacher, { merge: true });
                        } else {
                          // teacher.moved
                          // batch.set(
                          //   this.userData.school.ref
                          //     .collection("teachers")
                          //     .doc(teacher.PERSON_ID.toString()),
                          //   teacher,
                          //   { merge: true }
                          // );
                        }
                      } else {
                        blockedTeachers++;
                        batch.set(
                          this.userData.school.ref
                            .collection("teachers-reserved")
                            .doc(teacher.PERSON_ID.toString()),
                          teacher,
                          { merge: true }
                        );
                      }
                      // }
                    });
                    batch.commit().then(() => {
                      this.userData.school.ref.update({
                        _es_teacher_read: true,
                        _es_teacher_readAt: new Date(),
                      });
                      if (newTeachers > 0) {
                        var str =
                          "Нийт " +
                          newTeachers +
                          " багшийн мэдээлэл шинэчиллээ. ";

                        if (blockedTeachers > 0)
                          str =
                            str +
                            blockedTeachers +
                            " багшийн мэдээлэл бүрэн биш байна!";

                        this.$swal.fire(str);
                      } else {
                        this.$swal.fire("Шинээр багш нэмэгдээгүй байна.");
                      }
                      this.teachers.forEach((teacher) => {
                        this._loadTeacherLessons(teacher);
                      });
                      //employeed
                      // var batch2 = fb.db.batch();
                      // var movedTeacherCounter = 0;
                      // for (const existingTeacher of this.teachers) {
                      //   var found = esisResult.find(
                      //     (tt) => tt.PERSON_ID == existingTeacher.PERSON_ID
                      //   );
                      //   if (found) {
                      //     found.stillEmployeed = true;
                      //   } else {
                      //     movedTeacherCounter++;
                      //     batch2.set(
                      //       this.userData.school.ref
                      //         .collection("teachers")
                      //         .doc(existingTeacher.PERSON_ID.toString()),
                      //       { moved: true, movedAt: new Date() },
                      //       { merge: true }
                      //     );
                      //   }
                      // }
                      // batch2.commit().then(() => {
                      //   if (movedTeacherCounter > 0)
                      //     this.$swal.fire(
                      //       movedTeacherCounter +
                      //         " . Xэрэв буцааx бол та ESIS дээр төлвийг өөрчилж энд татаx эсвэл яаралтай бол шилжсэн багш xэсэгт орж буцааж болно!"
                      //     );
                      // });
                    });
                  } else {
                    alert("Мэдээлэл татаxад алдаа гарлаа.");
                    this.loading = false;
                    this.fb.db
                      .collection("esis-connection-failed")
                      .doc(this.userData.school.id)
                      .set({
                        createdAt: new Date(),
                        accessLink:
                          "https://hub.esis.edu.mn/svc/api/hub/teacher/list",
                        school: this.userData.school,
                      });
                  }
                });
            }
          });
        }
      }
    },
    _isExists(item) {
      return this.teachers.find(
        (teacher) => item.PERSON_ID == teacher.PERSON_ID
      );
    },

    _changeFirstNameToLastName() {
      this.filteredTeachers.forEach((teacher) => {
        teacher.ref
          .update({ firstName: teacher.lastName, lastName: teacher.firstName })
          .then(() => {
            console.log("saving..");
          });
      });
    },
    capitalized(name) {
      const capitalizedFirst = name[0].toUpperCase();
      const rest = name.slice(1);

      return capitalizedFirst + rest;
    },
    _previewSaveBulk() {
      this.preListOfBulk = [];
      if (this.bulkText != null && this.bulkText != "") {
        var arrayOfLines = this.bulkText.match(/[^\r\n]+/g);
        if (arrayOfLines != null && arrayOfLines.length > 0) {
          arrayOfLines.forEach((value) => {
            var lineArray = value.trim().split("\t");
            var newItem = { createdAt: new Date() };

            if (this.columnNumber == 3) {
              if (lineArray[1] && lineArray[1] != null && lineArray[1] != "")
                newItem["phone"] = lineArray[1].trim();

              if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
                newItem["email"] = lineArray[2].trim().toLowerCase();
              else newItem["email"] = null;

              if (lineArray[0] && lineArray[0] != null && lineArray[0] != "") {
                var fn1 = lineArray[0].trim();
                newItem["firstName"] = this.capitalized(fn1);
                if (newItem.firstName.indexOf(".") > -1) {
                  newItem.firstName = newItem.firstName.substring(
                    newItem.firstName.indexOf(".") + 1,
                    newItem.firstName.length
                  );
                  if (newItem.firstName[0])
                    newItem.lastName = newItem.firstName[0];
                  else newItem.lastName = null;
                }
              }
            } else if (this.columnNumber == 4) {
              var firstNameIndex = 0;
              var lastNameIndex = 0;

              if (this.firstNameFirst == false) {
                firstNameIndex = 1;
                lastNameIndex = 0;
              } else {
                firstNameIndex = 0;
                lastNameIndex = 1;
              }

              if (
                lineArray[firstNameIndex] &&
                lineArray[firstNameIndex] != null &&
                lineArray[firstNameIndex] != ""
              ) {
                var x1 = lineArray[firstNameIndex].trim();
                newItem["firstName"] = this.capitalized(x1);
                if (newItem.firstName.indexOf(".") > -1) {
                  newItem.firstName = newItem.firstName.substring(
                    newItem.firstName.indexOf(".") + 1,
                    newItem.firstName.length
                  );
                }
              }

              if (
                lineArray[lastNameIndex] &&
                lineArray[lastNameIndex] != null &&
                lineArray[lastNameIndex] != ""
              ) {
                var fn2 = lineArray[lastNameIndex].trim();
                newItem["lastName"] = this.capitalized(fn2);
                if (newItem.lastName.indexOf(".") > -1) {
                  newItem.lastName = newItem.lastName.substring(
                    newItem.lastName.indexOf(".") + 1,
                    newItem.lastName.length
                  );
                }
              }
              if (lineArray[2] && lineArray[2] != null && lineArray[2] != "")
                newItem["phone"] = lineArray[2].trim();

              if (lineArray[3] && lineArray[3] != null && lineArray[3] != "")
                newItem["email"] = lineArray[3].trim().toLowerCase();
              else newItem["email"] = null;
            }

            newItem.role = "teacher";
            newItem.roleName = "Багш";
            newItem.accountClosed = false;
            newItem.deleted = false;
            newItem.schoolRef = this.userData.school.ref;
            this.preListOfBulk.push(newItem);
          });
        }
      }
    },
    _setupp() {
      fb.db
        .collection("xDuties")
        .where("code", "==", "teacher")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.systemRoles = [];
          querySnapshot.forEach((doc) => {
            let xDuty = doc.data();
            xDuty.id = doc.id;
            xDuty.ref = doc.ref;
            xDuty.role = xDuty.code;
            this.systemRoles.push(xDuty);
          });

          if (this.userData.school) {
            this.userData.school.ref
              .collection("xDuties")
              .where("code", "==", "teacher")
              .orderBy("name", "asc")
              .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  let xDuty = doc.data();
                  xDuty.id = doc.id;
                  xDuty.ref = doc.ref;
                  xDuty.role = xDuty.code;

                  this.systemRoles.push(xDuty);
                });
              });
          }
        });
      if (this.userData.school) {
        this.userData.school.ref
          .collection("zanList")
          .orderBy("academicOrganizationId", "asc")
          .onSnapshot((querySnapshot) => {
            this.zanDepartments = [];
            var counter = 0;
            querySnapshot.forEach((doc) => {
              let item = doc.data();
              item.id = doc.id;
              item.ref = doc.ref;
              counter++;
              item.name2 =
                counter +
                ". " +
                item.academicOrgName +
                "(" +
                item.academicOrganizationId +
                ")";
              this.zanDepartments.push(item);
            });
          });
      }
    },
    showAlert(a) {
      console.log(a);
    },
    _detail(item) {
      this.$router.push({
        name: "UserDetail",
        params: {
          userrefid: item.ref.path,
        },
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _deleteItemConfirm() {
      // this.editedItem.ref.update({ deleted: true }).then(() => {
      this.editedItem.ref.delete().then(() => {
        this.userData.school.ref.update({
          teachersNumber: fb.firestore.FieldValue.increment(-1),
        });
        this.closeDelete();
      });
    },
    _deleteItem(item) {
      this.editedIndex = this.teachers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem["DATE_OF_BIRTH"] = this.editedItem["DATE_OF_BIRTH"]
        ? this.editedItem["DATE_OF_BIRTH"].replace("T00:00:00.000Z", "")
        : null;
      this.selectedSystemRoles = {
        role: this.editedItem.role,
        roleName: this.editedItem.roleName,
        roleRef: this.editedItem.roleRef,
        // id: this.editedItem.roleRef.id,
      };
      this.newDialog = true;
    },
    _close() {
      this.newDialog = false;
      this.messageNotification = null;
      this.selectedDepartment = null;
      this.selectedSystemRoles = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    _save() {
      if (
        this.editedItem.firstName &&
        this.editedItem.firstName.trim() !== "" &&
        this.editedItem.lastName &&
        this.editedItem.lastName.trim() !== "" &&
        this.editedItem.email &&
        this.editedItem.email.trim().length > 4 &&
        this.editedItem["DATE_OF_BIRTH"] &&
        this.editedItem["DATE_OF_BIRTH"].length == 10
      ) {
        var x = {};
        x.createdAt = new Date();
        x.firstName = this.editedItem.firstName;
        x["FIRST_NAME"] = this.editedItem.firstName;
        x["ASSIGNMENT_NAME"] = this.editedItem["ASSIGNMENT_NAME"]
          ? this.editedItem["ASSIGNMENT_NAME"]
          : null;
        x["DATE_OF_BIRTH"] =
          this.editedItem["DATE_OF_BIRTH"] + "T00:00:00.000Z";

        if (this.editedItem.lastName) {
          x.lastName = this.editedItem.lastName;
          x["LAST_NAME"] = this.editedItem.lastName;
          x["DISPLAY_NAME"] =
            this.editedItem.lastName[0].toUpperCase() +
            "." +
            this.editedItem.firstName;
        }
        if (this.editedItem.email) {
          x.email = this.editedItem.email.toLowerCase();
          x.USERNAME = this.editedItem.email.toUpperCase();
          x.PERSON_ID = this.editedItem.email.toLowerCase();
        }

        // if (this.editedItem.phone) x.phone = this.editedItem.phone;

        // if (
        //   this.selectedDepartment != null &&
        //   this.selectedDepartment != undefined
        // ) {
        //   x["departmentName-2022"] = this.selectedDepartment.name;
        //   x["department-2022"] = this.selectedDepartment.ref;
        // }
        // if (this.selectedSystemRoles) {
        //   x.roleRef = this.selectedSystemRoles.ref
        //     ? this.selectedSystemRoles.ref
        //     : this.selectedSystemRoles.roleRef;
        //   x.roleName = this.selectedSystemRoles.roleName;
        //   x.role = this.selectedSystemRoles.role;
        // }
        if (this.userData.schoolIndex) {
          x.schoolIndex = this.userData.schoolIndex;
        }
        if (!this.editedItem.ref) {
          x.role = "teacher";
          x.roleName = "Багш";
          x.accountClosed = false;
          x.deleted = false;
          x.schoolRef = this.userData.school.ref;
          x["_es_teacher_read"] = false;
          x["manuallyAdded"] = true;
          x["role"] = "teacher";
          x["roleName"] = "Багш";
          x["INSTRUCTOR_TYPE_ID"] = 2;
          x.createdAt = new Date();
          x.INSTITUTION_ID =
            this.userData.school._esis_schoolInfo.legalEntityId;

          // Object.assign(this.participants[this.editedIndex], this.editedItem);
          this.userData.school.ref
            .collection("teachers")
            .doc()
            .set(x, { merge: true })
            .then((aa) => {
              console.log(
                "saved!",
                aa,
                this.userData.school.ref.collection("teachers").doc(x.USERNAME)
              );
              this._close();
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });
          // this.userData.school.ref.update({
          //   teachersNumber: fb.firestore.FieldValue.increment(1),
          // });
        } else {
          x.updatedAt = new Date();
          this.editedItem.ref.update(x).then(() => {
            this._close();
          });
        }
      }
    },
    // saveBulk2() {
    //   var batch = fb.db.batch();
    //   if (this.preListOfBulk && this.preListOfBulk.length > 0) {
    //     this.preListOfBulk.forEach((value) => {
    //       var xx = fb.db.collection(this.path).doc();
    //       batch.set(xx, value, { merge: true });
    //     });
    //     // this.userData.school.ref.update({
    //     //   teachersNumber: fb.firestore.FieldValue.increment(
    //     //     this.preListOfBulk.length
    //     //   ),
    //     // });
    //   }
    //   batch.commit().then(() => {
    //     this.bulkText = null;
    //     this.preListOfBulk = null;
    //   });
    // },
  },
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = new Date(val);
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
.titleDialogClass {
  color: red;
}

.textDialogClass {
  color: blue;
}

.preTable {
  border: 1px solid black;
  border-spacing: 0px;
}

.preTable th,
.preTable td {
  border: 1px solid black;
  border-spacing: 0px;
  width: 25%;
  border-collapse: collapse;
  padding: 0px 2px 0px 2px;
}

[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 2;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.v-tabs__slider {
  background-color: red;
  /* Set the border color */
  height: 2px;
  /* Set the border height */
}
</style>
